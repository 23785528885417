<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details :title="sidebar_title" @close-sidebar="closeSidebar">
                <technical-contact-details
                    v-if="sidebar.technical_contact"

                    ref="technical-contact-details"

                    @saved="onSavedTechnicalContactDetails"
                    @close-sidebar="closeSidebar"
                />

                <billing-contact-details
                    v-else-if="sidebar.billing_contact"

                    ref="billing-contact-details"

                    @saved="onSavedBillingContactDetails"
                    @close-sidebar="closeSidebar"
                />

                <nameservers-details
                    v-else-if="sidebar.nameservers"

                    ref="nameservers-details"

                    @saved="onSavedNameserversDetails"
                    @close-sidebar="closeSidebar"
                />
            </app-sidebar-details>
        </template>

        <div class="page-domains-default-settings container">
            <app-loader v-if="is_loading" fixed-on="desktop"></app-loader>

            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Default Settings</h1>
                </div>

                <div class="col-6 col-mob-12">
                    <app-table-details
                        title="Default Technical Contact"

                        :rows="rows.technical_contact"
                        :data="technical_contact_info"

                        :editable="{ action: 'edit', mobile: 'edit-mobile' }"

                        @edit="onEditTechnicalContactDetails"
                        @edit-mobile="onEditMobileTechnicalContactDetails"
                    />
                </div>

                <div class="col-6 col-mob-12">
                    <app-table-details
                        title="Default Billing Contact"

                        :rows="rows.billing_contact"
                        :data="billing_contact_info"

                        :editable="{ action: 'edit', mobile: 'edit-mobile' }"

                        @edit="onEditBillingContactDetails"
                        @edit-mobile="onEditMobileBillingContactDetails"
                    />
                </div>

                <div class="col-6 col-mob-12">
                    <app-table-details
                        title="Default Nameservers"

                        :rows="rows.nameservers"
                        :data="nameservers_info"

                        :editable="{ action: 'edit', mobile: 'edit-mobile' }"

                        @edit="onEditNameserversDetails"
                        @edit-mobile="onEditMobileNameserversDetails"
                    />
                </div>
            </div>
        </div>
    </base-layout>
</template>

<script>
import baseLayout from '@/views/layouts/base-layout'

import appSidebarDetails from '@/components/app-sidebar-details'

import technicalContactDetails from '@/views/domains/default-settings/technical-contact'
import billingContactDetails   from '@/views/domains/default-settings/billing-contact'
import nameserversDetails      from '@/views/domains/default-settings/nameservers'

import appLoader from '@/components/app-loader'
import appTableDetails from '@/components/app-table-details'

const TECHNICAL_CONTACT_INFO = {
    FirstName:   'Joshua',
    LastName:    'Hanson',
    Email:       'joshua-hanson@iqhive.com',
    Address:     '11-21 Fitzpatrick Street',
    City:        'Wellington',
    PostalCode:  '6037',
    Country:     'New Zealand',
    PhoneNumber: '+64219191191',
}

const BILLING_CONTACT_INFO = {
    FirstName:   'Joshua',
    LastName:    'Hanson',
    Email:       'joshua-hanson@iqhive.com',
    Address:     '11-21 Fitzpatrick Street',
    City:        'Wellington',
    PostalCode:  '6037',
    Country:     'New Zealand',
    PhoneNumber: '+64219191191',
}

const NAMESERVERS_INFO = {
    NameServer1: '223.204.18.102',
    NameServer2: '153.170.53.181',
    NameServer3: '249.32.79.61',
}

export default {
    components: {
        baseLayout,

        appSidebarDetails,
        technicalContactDetails,
        billingContactDetails,
        nameserversDetails,

        appLoader,
        appTableDetails,
    },

    data() {
        return {
            technical_contact: null,
            billing_contact: null,
            nameservers: null,

            rows: {
                technical_contact: [
                    { key: 'FirstName',   title: 'First name'   },
                    { key: 'LastName',    title: 'Last name'    },
                    { key: 'Email',       title: 'E-mail'       },
                    { key: 'Address',     title: 'Address'      },
                    { key: 'Country',     title: 'Country'      },
                    { key: 'PhoneNumber', title: 'Phone number' },
                ],

                billing_contact: [
                    { key: 'FirstName',   title: 'First name'   },
                    { key: 'LastName',    title: 'Last name'    },
                    { key: 'Email',       title: 'E-mail'       },
                    { key: 'Address',     title: 'Address'      },
                    { key: 'Country',     title: 'Country'      },
                    { key: 'PhoneNumber', title: 'Phone number' },
                ],

                nameservers: [
                    { key: 'NameServer1', title: 'Name Server 1' },
                    { key: 'NameServer2', title: 'Name Server 2' },
                    { key: 'NameServer3', title: 'Name Server 3' },
                ],
            },

            sidebar: {
                technical_contact: false,
                billing_contact:   false,
                nameservers:       false,
            },

            loading: {
                technical_contact: false,
                billing_contact:   false,
                nameservers:       false,
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.loadTechnicalContactInfo()
            this.loadBillingContactInfo()
            this.loadNameserversInfo()
        },

        loadTechnicalContactInfo() {
            const delay_in_ms = 1000

            this.loading.technical_contact = true

            setTimeout(() => {
                this.technical_contact = TECHNICAL_CONTACT_INFO

                this.loading.technical_contact = false
            }, delay_in_ms)
        },

        loadBillingContactInfo() {
            const delay_in_ms = 1000

            this.loading.billing_contact = true

            setTimeout(() => {
                this.billing_contact = BILLING_CONTACT_INFO

                this.loading.billing_contact = false
            }, delay_in_ms)
        },

        loadNameserversInfo() {
            const delay_in_ms = 1000

            this.loading.nameservers = true

            setTimeout(() => {
                this.nameservers = NAMESERVERS_INFO

                this.loading.nameservers = false
            }, delay_in_ms)
        },

        onEditTechnicalContactDetails() {
            if (this.sidebar.technical_contact) {
                this.sidebar.technical_contact = false

                this.closeSidebar()
            } else {
                this.sidebar.technical_contact = true
                this.sidebar.billing_contact   = false
                this.sidebar.nameservers       = false

                this.openSidebar()
            }
        },

        onEditMobileTechnicalContactDetails() {
            this.$router.push({ name: 'domains-default-settings-technical-contact' })
        },

        onSavedTechnicalContactDetails() {
            this.loadTechnicalContactInfo()
        },

        onEditBillingContactDetails() {
            if (this.sidebar.billing_contact) {
                this.sidebar.billing_contact = false

                this.closeSidebar()
            } else {
                this.sidebar.technical_contact = false
                this.sidebar.billing_contact   = true
                this.sidebar.nameservers       = false

                this.openSidebar()
            }
        },

        onEditMobileBillingContactDetails() {
            this.$router.push({ name: 'domains-default-settings-billing-contact' })
        },

        onSavedBillingContactDetails() {
            this.loadBillingContactInfo()
        },

        onEditNameserversDetails() {
            if (this.sidebar.nameservers) {
                this.sidebar.nameservers = false

                this.closeSidebar()
            } else {
                this.sidebar.technical_contact = false
                this.sidebar.billing_contact   = false
                this.sidebar.nameservers       = true

                this.openSidebar()
            }
        },

        onEditMobileNameserversDetails() {
            this.$router.push({ name: 'domains-default-settings-nameservers' })
        },

        onSavedNameserversDetails() {
            this.loadNameserversInfo()
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.$emit('close-sidebar')

            switch (true) {
                case this.sidebar.technical_contact: {
                    this.$refs['technical-contact-details'].reset()
                } break

                case this.sidebar.billing_contact: {
                    this.$refs['billing-contact-details'].reset()
                } break

                case this.sidebar.nameservers: {
                    this.$refs['nameservers-details'].reset()
                } break
            }
        },
    },

    computed: {
        is_loading() {
            return this.loading.technical_contact
                || this.loading.billing_contact
                || this.loading.nameservers
        },

        technical_contact_info() {
            return this.technical_contact ? {
                FirstName:   this.technical_contact.FirstName,
                LastName:    this.technical_contact.LastName,
                Email:       this.technical_contact.Email,
                Address:     `${this.technical_contact.Address}, ${this.technical_contact.City} ${this.technical_contact.PostalCode}`,
                Country:     this.technical_contact.Country,
                PhoneNumber: this.technical_contact.PhoneNumber,
            } : {}
        },

        billing_contact_info() {
            return this.billing_contact ? {
                FirstName:   this.billing_contact.FirstName,
                LastName:    this.billing_contact.LastName,
                Email:       this.billing_contact.Email,
                Address:     `${this.billing_contact.Address}, ${this.billing_contact.City} ${this.billing_contact.PostalCode}`,
                Country:     this.billing_contact.Country,
                PhoneNumber: this.billing_contact.PhoneNumber,
            } : {}
        },

        nameservers_info() {
            return this.nameservers ? {
                NameServer1: this.nameservers.NameServer1,
                NameServer2: this.nameservers.NameServer2,
                NameServer3: this.nameservers.NameServer3,
            } : {}
        },

        sidebar_title() {
            let title = ''

            switch (true) {
                case this.sidebar.technical_contact: {
                    title = 'Default Technical Contact'
                } break

                case this.sidebar.billing_contact: {
                    title = 'Default Billing Contact'
                } break

                case this.sidebar.nameservers: {
                    title = 'Name Servers'
                } break
            }

            return title
        },
    },
}
</script>

<style lang="scss">
.page-domains-default-settings {
    padding-bottom: 80px;
    
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .row {
        & > .col-6 {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &::after {
            content: "";
            display: block;
            height: 0;
            padding: 0 $container-padding / 2;
        }

        &>.col-6,
        &::after {
            flex: 1 0 50%;
            max-width: 100%;
            min-width: 275px;
        }
    }
}

@media (max-width: $tablet-size) {
    // .page-domains-default-settings {}
}

@media (max-width: $mobile-size) {
    .page-domains-default-settings {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .app-table-details {
            .details {
                li {
                    flex-wrap: nowrap;

                    & > :nth-child(1) {
                        width: fit-content;
                    }

                    & > :nth-child(2) {
                        justify-content: flex-end;
                        overflow: hidden;

                        span {
                            @include text-overflow();
                        }
                    }
                }
            }
        }
    }
}
</style>